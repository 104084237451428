<template>
   <div class="flex-1 flex flex-col min-h-screen h-full overflow-x-hidden overflow-y-auto">
      <header>
         <nav class="bg-indigo-900 shadow">
            <div class="container px-6 py-3 mx-auto md:flex md:justify-between md:items-center">
                  <div class="flex items-center justify-between">
                     <div>
                        <router-link to="/" class="text-2xl font-bold text-white transition-colors duration-200 transform hover:text-gray-100">Escrowlock</router-link>
                     </div>
                     <!-- Mobile menu button -->
                     <div class="flex md:hidden">
                        <button type="button" class="text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400" aria-label="toggle menu">
                              <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                                 <path fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"></path>
                              </svg>
                        </button>
                     </div>
                  </div>

                  <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
                  <div class="items-center md:flex" v-if="!isLoggedIn">
                     <div class="md:flex flex-col hidden md:flex-row md:mx-6">
                        <router-link to="/auth/sign-in" class="text-sm font-medium text-white transition-colors duration-200 transform hover:text-gray-100 md:mx-4 md:my-0">Login</router-link>
                        <router-link to="/auth/sign-up" class="text-sm font-medium text-white transition-colors duration-200 transform hover:text-gray-100 md:mx-4 md:my-0">Sign Up</router-link>
                        <router-link to="/help" class="text-sm font-medium text-white transition-colors duration-200 transform hover:text-gray-100 md:mx-4 md:my-0">Help and FAQs</router-link>   
                     </div>
                  </div>
                  <div class="items-center md:flex" v-else>
                     <div class="md:flex flex-col hidden md:flex-row md:mx-6">
                        <router-link to="/account/user/dashboard" class="text-sm font-medium text-white transition-colors duration-200 transform hover:text-gray-100 md:mx-4 md:my-0">Dashboard</router-link>
                        <span @click="logout()" class="text-sm font-medium text-white transition-colors duration-200 transform hover:text-gray-100 cursor-pointer md:mx-4 md:my-0">Sign out</span>
                        <router-link to="/help" class="text-sm font-medium text-white transition-colors duration-200 transform hover:text-gray-100 md:mx-4 md:my-0">Help and FAQs</router-link>   
                     </div>
                  </div>
            </div>
         </nav>
      </header>

      <main class="flex-grow">
         <router-view />
      </main>
      
      <footer class="inset-x-0 bottom-0 w-full bg-white border-heading p-5 mt-10 text-gray-500 font-medium 2xl:p-5">
        <div class="mx-4">
          <div class="flex flex-col-reverse md:flex-row text-center md:justify-center mx-auto max-w-full px-4 md:px-8 2xl:px-16">
            <p class="text-xs lg:text-sm leading-6">
              Copyright &copy; {{ new Date().getFullYear() }} <a class="font-bold text-indigo-900 transition-colors duration-200 ease-in-out hover:text-body" href="https://escrowlock.com">Escrowlock.</a> All rights reserved
            </p>
          </div>
        </div>
      </footer>
   </div>
</template>

<script>
export default {
   name: "PublicLayout",

   data() {
      return {
         isLoggedIn: false
      }
   },

   methods: {
      async logout() {
         try {
         let res = await this.$store.dispatch('auth/token_terminate')
         
         this.$toast.success(res.message)
         } catch (error) {
            this.$toast.error(error.response.message)
            if(error.response && error.response.status === 401) {
               this.$store.commit('logout');
               this.router.push('/auth/sign-in');
            }
         }
      }
   },

   mounted() {
      if(this.$store.state.auth.token != null) {
         this.isLoggedIn = true
      }
   }
}
</script>